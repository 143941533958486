/* Burger Toogle */
const burgerButtonWrapper = document.querySelector('.burger-button-wrapper');
burgerButtonWrapper.addEventListener('click', function() {
    this.classList.toggle('burger-active');
    const parentElement = this.parentElement.parentElement;
    const navElement = parentElement.querySelector('nav');
    navElement.classList.toggle('nav-active');
    document.body.classList.toggle('menu-active');
});

/* OnScroll Progressbar */
window.addEventListener('scroll', function() {
    const indicatorSize = ((window.scrollY) / ((Math.max( document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight )) - window.innerHeight)) * 100 + '%';
    document.querySelector('.indicator').style.setProperty('--indicator-size', indicatorSize);
}, { passive: true });

const typerElements = document.querySelectorAll('.typer');
if (typerElements && typerElements.length > 0) {
    (async () => {
        try {
            const module = await import('./../../cms-public/libs/autotyping.plugin');
            const Typer = module.Typer;
            typerElements.forEach(element => new Typer(element));
        } catch (error) {}
    })();
}

/* Splide JS Initialization */
const splideElements = document.querySelectorAll('.splide-vertical .splide');
if (splideElements && splideElements.length > 0) {
    Promise.all([
        import('./../../cms-public/libs/splide-bundle.min.js'),
        import('./../../cms-public/libs/autoscroll.plugin.js')
    ]).then(([Splide, Autoscroll]) => {
            for (let i = 0; i < splideElements.length; i++) {
                new Splide.default(splideElements[i], {
                    type: 'loop',
                    drag: 'free',
                    gap: '2rem',
                    lazyLoad: 'nearby',
                    arrows: false,
                    pagination: false,
                    wheel: true,
                    perPage: 2,
                    autoScroll: {
                        speed: 0.5,
                    },
                    breakpoints: {
                        600: {
                            perPage: 1,
                            gap: '1rem',
                        },
                    },
                }).mount(window.splide.Extensions);
            }
        }).catch(error => console.error(error));
}

const themeSwitch = document.querySelector('.theme-toggle');
function switchTheme(e) {
    if (document.body.classList.contains('dark-mode')) {
        document.body.classList.remove('dark-mode');
        e.target.classList.remove('dark-mode-active');
        document.cookie = "theme=light; path=/;";
    } else {
        document.body.classList.toggle('dark-mode');
        e.target.classList.toggle('dark-mode-active');
        document.cookie = "theme=dark; path=/;";
    }
}
themeSwitch.addEventListener('click', switchTheme, false);

if (document.getElementById('cookie_consent')) {
    document.getElementById('cookie_consent').addEventListener('click', function() {
        const parent = this.parentElement.parentElement;
        parent.parentElement.classList.remove('cookie-active');
        const date = new Date();
        date.setTime(date.getTime() + (91 * 24 * 60 * 60 * 1000));
        const expires = date.toUTCString();
        document.cookie = "consent=accepted; expires="+ expires + "; path=/;";
    });
}

const ctaLinks = document.querySelectorAll('.cta-track');
if (ctaLinks.length > 0) {
    ctaLinks.forEach(link => {
        link.addEventListener('click', function () {
            const dataCategory = this.getAttribute('data-category');
            const ariaLabel = this.getAttribute('aria-label');
            gtag('event', 'click', {
                'event_category': dataCategory,
                'event_label': ariaLabel
            });
        });
    });
}

function onScrollSusi(elementClass) {
    const options = { root: null, rootMargin: "0px", threshold: .5 };
    const observer = new IntersectionObserver(handleIntersect, options);
    document.querySelectorAll(`.${elementClass}`).forEach((element) => {
        observer.observe(element);
    });

    function handleIntersect(entries) {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                entry.target.style.width = "0%";
            }
        });
    }
}
onScrollSusi("animated-overlay");


let gallery = document.getElementById('animated_gallery');
if (gallery) {
    lightGallery(gallery, {
        animateThumb: false,
        zoomFromOrigin: false,
        toggleThumb: true,
        plugins: [lgZoom],
        download: false,
        controls: true,
        enableZoom: true,
    });
    gallery.addEventListener('lgAfterOpen', () => {
        document.body.style.overflow = 'hidden';
    });
    gallery.addEventListener('lgBeforeClose', () => {
        document.body.style.overflow = 'auto';
    });
}




